<template>
  <TepmplateBlock content-class="mt-4" mod-class title-page="Банковские данные">
    <div class="banking-data main-container">
      <b-alert show>
        Эти данные вы не сможете сами отредактировать, чтобы поменять их нужно
        будет связаться с менеджером
      </b-alert>
      <div class="intro-tip p-2 w-50 text-center">
        Заполните поля и нажмите кнопку "Сохранить"
      </div>
      <form @submit.prevent="onSubmit">
        <Input
          @input="onInputChange"
          v-model="bankInfo.bank_account_num"
          label="№ счета"
          :required="true"
          :is-need-validation="true"
          :class="{ 'over-top': isIntroPassed === 'false' }"
          class="tip-anchor"
        />

        <Input
          @input="onInputChange"
          v-model="bankInfo.bank_name"
          label="Наименование банка"
          :required="true"
          :is-need-validation="true"
          :class="{ 'over-top': isIntroPassed === 'false' }"
        />

        <Input
          @input="onInputChange"
          v-model="bankInfo.bank_bik"
          label="БИК Банка"
          :required="true"
          :is-need-validation="true"
          :class="{ 'over-top': isIntroPassed === 'false' }"
        />

        <!-- <Input
          @input="onInputChange"
          v-model="bankInfo.bank_inn"
          label="ИНН Банка"
          :required="true"
          :is-need-validation="true"
          /> -->

        <Input
          @input="onInputChange"
          v-model="bankInfo.bank_ks"
          label="к/с банка"
          :required="true"
          :is-need-validation="true"
          :class="{ 'over-top': isIntroPassed === 'false' }"
        />
        <span class="error" v-if="error.length > 0">{{ error }}</span>
        <div class="banking-data__btn-group justify-content-center">
          <router-link
            type="button"
            to="/admin-profile"
            class="btn btn-primary banking-data__btn-prev"
            :class="{ 'over-top': isIntroPassed === 'false' }"
            v-if="isIntroPassed === 'true'"
          >
            Назад
          </router-link>

          <button
            type="submit"
            class="btn btn-second"
            :class="{ 'over-top': isIntroPassed === 'false' }"
          >
            Сохранить
          </button>
        </div>
      </form>
    </div>
    <div v-if="isIntroPassed === 'false'" class="dark-intro-background">
      <div
        class="intro-tip-wrapper d-flex justify-content-end align-items-center"
      ></div>
    </div>
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "../../components/TepmplateBlock";
import Input from "../../components/Form/FormElement/Input";
import RequestManager from "../../function/request/RequestManager";
import { doPoseTip } from "@/utils";
import { CompanyApi } from "../../function/request/apiV2";

export default {
  name: "BankingData",
  components: { Input, TepmplateBlock },
  async created() {
    //this.isIntroPassed = localStorage.getItem("isIntroPassed");
    const { items } = await this.companyApi.api.listCompanies({
      ids: [this.$route.params.id],
      limit: 1000000,
      sort: "id"
    });
    this.companyInfo = items[0];
    console.log(this.companyInfo);

    this.bankInfo.bank_account_num = this.companyInfo.bankAccountNum;
    this.bankInfo.bank_bik = this.companyInfo.bankBik;
    this.bankInfo.bank_ks = this.companyInfo.bankKs;
    this.bankInfo.bank_name = this.companyInfo.bankName;

    // RequestManager()
    //   .getBankInfo()
    //   .then(data => {
    //     this.bankInfo = data;
    //   });
  },
  mounted() {
    doPoseTip(".tip-anchor", "top");
  },
  data() {
    return {
      error: "",
      companyInfo: {},
      companyApi: new CompanyApi(),
      bankInfo: {
        // bank_inn: null,
        bank_account_num: null,
        bank_bik: null,
        bank_ks: null,
        bank_name: null,
        id: ""
      },
      isIntroPassed: true
    };
  },
  methods: {
    onInputChange() {
      this.error = "";
    },
    onSubmit() {
      let error = "";
      for (let key in this.bankInfo) {
        if (
          key !== "bank_inn" &&
          (this.bankInfo[key] === "" || this.bankInfo[key] === null)
        ) {
          error = "Заполните все поля";
        }
      }
      if (error.length === 0) {
        RequestManager()
          .updateBankInfo(this.bankInfo.id, this.bankInfo)
          .then(() => {
            localStorage.setItem("isAdminProfileFilled", "true");
            this.bank_bik = this.bankInfo.bank_bik;
            this.$router.push({ name: "AdminProfile" });
          });
      } else {
        this.error = error;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.error {
  display: block;
  width: 100%;
  font-size: 14px;
  text-align: center;
  color: red;
}
.scroll-container {
  margin: 0 auto;
  overflow: hidden;
}
.banking-data {
  margin: 0 auto;
  padding: 38px 72px 36px;
  width: 100%;
  max-width: 498px;
  height: 100%;
  overflow-y: auto;
  background: var(--main-card-color);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  position: relative;
  z-index: 1001;
}

.banking-data__btn-group {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 14px;
}

.banking-data__btn {
  max-width: 100%;
}

.banking-data__btn-prev {
  border: 1px solid #006b90;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.01));
  margin-right: 7px;
}
</style>
